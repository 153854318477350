.bg {
  background-color: #191fcb;
}

.logo-img {
  width: 58%;
}

.whatsapp_float {
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  left: 16px;
}

.whatsapp_float_btn {
  border-radius: 63%;
  height: 60px;
  width: 60px;
}

.show-menu {
  display: none;
}

.css-o69gx8-MuiCardMedia-root {
  width: none;
}

.head1 {
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
}
.head2 {
  color: white;
  font-weight: 100;
  font-size: 11px;
  line-height: 24px;
}

.bg1 {
  background: linear-gradient(45deg, #17449e, #000000cf);
}
.bg2{
  background-color: aliceblue;
}
